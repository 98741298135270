import { useState } from "react";
import styled from "styled-components";
import Collapse from "../components/Collapse";
import FAQItems from "./Items";

const Container = styled.div`
    max-width: 768px;
`;

const Title = styled.span`
    display: block;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
`;

const FAQ = () => {

    const [openCollapsesMap, setOpenCollasesMap] = useState<{ [key: string]: boolean }>({});

    return (
        <Container className="mx-auto px-1 md:px-4">

            <Title className="mb-6">
                FAQ
            </Title>

            {FAQItems.map(item => {
                const isOpen = !!openCollapsesMap[item.id];

                const clickHandler = () => {
                    setOpenCollasesMap({
                        ...openCollapsesMap,
                        [item.id]: !isOpen
                    });
                }

                return (
                    <Collapse
                        className="mb-6"
                        open={isOpen}
                        onClick={clickHandler}
                        key={item.id}
                        id={item.id}
                        content={item.content}
                        title={item.title}
                    />
                );
            })}
        </Container>
    )

}

export default FAQ;