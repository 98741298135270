
import { ComponentPropsWithRef } from "react";
import styled from "styled-components";

export type ButtonProps = ComponentPropsWithRef<'button'>;

const Button = styled.button<ButtonProps>`
    padding: 0.5rem 1.5rem;
    background-color: #11ca11;
    border-radius: 0.5rem;
    color: #392e2c;
    border: 3px solid #392e2c;
    font-size: 24px;
    cursor: pointer;

    &:disabled {
        opacity: .3;
        cursor: not-allowed;
    }
`;

export default Button;