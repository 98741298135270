import styled from "styled-components";
import useScreenSize from "./hooks/useScreenSize";

const Container = styled.div`
    text-align: center;
`;

const MobileApesBanner = styled.div`
    background-image: url(/images/mobileapebanner.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 450px;
`;

const ApesBanner = styled.div`
    background-image: url(/images/apebanner.jpg);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 600px;
`;

const Header = () => {
    const screenSize = useScreenSize();
    const isSmallScreen = screenSize === 'xs';

    const render = () => {
        if (isSmallScreen) {
            return <MobileApesBanner />
        } else {
            return <ApesBanner />
        }
    }

    return (
        <Container className="mt-8">
            {render()}
        </Container>
    )
}

export default Header;