
import { createContext, PropsWithChildren, useState } from "react";
import { ethers } from 'ethers';
import { Account } from "./Account"
import { toast } from "react-toastify";

export type WalletType = 'MetaMask' | 'WalletConnect';

export type AccountContextType = {
    account: Account | null;
    connect: (walletType: WalletType) => Promise<void>;
    disconnect: () => Promise<void>;
    isConnecting: boolean;
    isInitialized: boolean;
}

const WALLET_PROVIDER_KEY = 'walletProvider';

export const AccountContext = createContext<AccountContextType>(null as any);

export const AccountProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [account, setAccount] = useState<Account | null>(null);
    const [connecting, setConnecting] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const connect = async (walletType: WalletType) => {

        if (connecting) {
            return;
        }

        setConnecting(true);
        try {

            let walletProvider: any;
            if (walletType === 'MetaMask') {
                const isMetaMask = (window as any).ethereum?.isMetaMask;
                if (!isMetaMask) {
                    toast('No MetaMask found', { theme: 'colored', type: 'error' });
                    return;
                }

                walletProvider = (window as any).ethereum as any;
            } else {
                throw new Error(`Unknown wallet type ${walletType}`);
            }

            const provider = new ethers.providers.Web3Provider(walletProvider);

            if (walletType === 'MetaMask') {
                await provider.send("eth_requestAccounts", []) as string[];
            }

            const signer = provider.getSigner();
            const walletAddress = await signer.getAddress();
            const networkId = await signer.getChainId();

            // Add listeners start
            walletProvider.on("accountsChanged", async (walletAddresses: string[]) => {
                if (walletAddresses[0]) {
                    window.location.reload();
                }
            });

            walletProvider.on("chainChanged", () => {
                window.location.reload();
            });

            console.log(`Using account: ${walletAddress} (Network: ${networkId})`);

            setAccount({
                walletAddress,
                signer,
                networkId
            });

            setIsInitialized(true);
            localStorage.setItem(WALLET_PROVIDER_KEY, walletType);
        } finally {
            setConnecting(false);
        }


    }

    const disconnect = async () => {
        setAccount(null);
        window.location.reload();
    }

    const contextValue: AccountContextType = {
        account: account || null,
        isConnecting: connecting,
        isInitialized,
        connect,
        disconnect
    }

    return (
        <AccountContext.Provider value={contextValue}>
            {children}
        </AccountContext.Provider>
    );
}