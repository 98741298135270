import styled from "styled-components";
import useScreenSize from "./hooks/useScreenSize";

const Container = styled.div`
    position: relative;
`;

const BoxesContainer = styled.div`
    position: relative;
    height: 330px;
    overflow-y: hidden;
`;

const Ground = styled.div`
    position: absolute;
    background-color: black;
    bottom: 0;
    right: 0;
    left: 0;
    height: 120px;
`;

const Box1 = styled.img`
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 300px;
`;

const Box2 = styled.img`
    position: absolute;
    bottom: -50px;
    right: 50%;
    width: 300px;
    transform: translateX(50%);
`;

const Box3 = styled.img`
    position: absolute;
    bottom: 50px;
    right: 10%;
    width: 300px;
`;

const Footer = () => {
    const screenSize = useScreenSize();
    const isSmallScreen = screenSize === 'xs' || screenSize === 'sm' || screenSize === 'md';
    return (
        <Container className="mt-8">
            <BoxesContainer>
                <Ground className="flex">
                    {!isSmallScreen && (<Box1 src='/images/box.png' />)}
                    <Box2 src='/images/box.png' />
                    {!isSmallScreen && (<Box3 src='/images/box.png' />)}
                </Ground>
            </BoxesContainer>
        </Container >
    )
}

export default Footer;