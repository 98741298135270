import styled from "styled-components";
import { BiChevronDownSquare, BiChevronRightSquare } from 'react-icons/bi';

export type CollapseProps = {
    className?: string;
    content: React.ReactNode;
    id: string;
    onClick: () => void;
    open: boolean;
    title: string;
}

const Container = styled.div`
    width: 100%;
    border: 3px solid #392E2C;
    border-radius: .5rem;
    padding: .75rem
`;

const Button = styled.button``;

const Title = styled.div`
    text-align: left;
    font-size: 1.5rem;
    font-family: Gotham;
    font-weight: 600;
`;

const Content = styled.div`
    padding-left: 3.6rem;
    text-align: left;
    font-size: 1.2rem;
    font-family: Gotham;
    font-weight: 600;
`;

const PositionedIconContainer = styled.div`
    margin-top: .2rem;
    margin-right: 1.5rem;
`;

const Collapse: React.FC<CollapseProps> = ({
    className,
    content,
    id,
    onClick,
    open,
    title,
}) => {

    const conditionallyRenderContent = () => {
        if (open) {
            return (
                <Content className="mt-4">
                    {content}
                </Content>
            );
        }
    }

    return (
        <Container id={id} className={className}>
            <Button onClick={onClick} className='flex'>
                <PositionedIconContainer>
                    {open ? <BiChevronDownSquare size={30} /> : <BiChevronRightSquare size={30} />}
                </PositionedIconContainer>
                <Title>{title}</Title>
            </Button>
            {conditionallyRenderContent()}
        </Container>
    );
}

export default Collapse;