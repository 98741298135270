import { ListItem, SubListItem } from "./ListItem";

export type FAQItem = {
    id: string;
    title: string;
    content: React.ReactNode;
}

const FAQItems: FAQItem[] = [{
    id: 'whatis',
    title: 'What are ApeBitz?',
    content: (
        <>
            <p className='mb-4'>Introducing Apebitz, a versatile 3-dimensional Gotham Apes voxel avatar with endless possibilities. Each character has thousands of possible trait combinations and a ton of personality.  In addition to being a pretty cool PFP, voxel art opens a lot of new doors for building within web3. Metaverse compatible? Maybe. Lambo? Probably. Random airdrops, value creation and a ticket into the Gotham Jungleverse? Absolutely.</p>
            <p className='mb-4'>From the Banker Bros to the Handlebar Hipsters, all ApeBitz characters represent the urban hustle and grind with the ultimate goal of achieving financial freedom. Nothing will stop them.</p>
            <p>With everything we do, our focus is the holders. All ApeBitz holders will be rewarded over time with special perks and utility.</p>
        </>
    )
}, {
    id: 'utility',
    title: 'What about UtiLitEzz??',
    content: (
        <>
            <p className='mb-4'>ApeBitz is an incredibly small supply, which of course, was done with purpose.  This small supply will allow us to keep rewarding ApeBitz holders throughout the Gotham Apes journey. Some of the announced perks are:</p>

            <ul className="ml-4 mb-4">
                <ListItem> Potential Slothtopia + other metaverse project compatibility</ListItem>
                <ListItem> Discord Channel Access + Holders RoleHolders only giveaways & contests</ListItem>
                <ListItem> Future IRL Event Access</ListItem>
                <ListItem> Generative Mint Pass</ListItem>
                <ul className="ml-6">
                    <SubListItem>1+ Token = WL for all future generative mints</SubListItem>
                    <SubListItem>2+ token = Free mint  + WL for all future generative mints</SubListItem>
                    <SubListItem>3+ Tokens = ????</SubListItem>
                </ul>
            </ul>

            <p>Don’t be surprised if additional perks are introduced strategically along the way.</p>
        </>
    )
}, {
    id: 'wenmeta',
    title: 'Wen Metaverse?',
    content: (
        <p>
            Most of the metaverse projects and lands are still in infancy and are currently being developed now.  Lots of very cool things being built. We are currently testing Apebitz in Slothtopia and are in discussions with some other projects as well. Our goal is to eventually have Apebitz characters usable inside several compatible worlds. In the meantime we will keep building and creating value for our holders.
        </p>
    )
}, {
    id: 'whatnext',
    title: 'What’s next?',
    content: (
        <>
            <p className='mb-4'>Our 999 supply generative collection is deep under development. We plan launch this collection at the end of Q3 2022.  So far we have delivered on our roadmap on time and in several places early. Stay posted for more updates and information as the project develops. </p>
            <p>In the background, we also have plans for some other cool shit and fun drops for all holders.  Free mints, burn tokenomics, IP rights, downloadable files, IRL goods and collaboration projects are all on the table. You can find our public roadmap HERE.  More updates soon.</p>
        </>
    )
}, {
    id: 'who',
    title: 'Who is running this project?',
    content: (
        <>
            <p className='mb-4'>Mofo is the Founder & CEO and runs all creative & content direction, brand strategy, marketing & operations. eoBoloo is our lead illustrator and the talent behind the genesis and generative collections. The art team consists of 3 brilliant artists, designers and animators.</p>
            <p className='mb-4'>Community team is being assembled. Are you a Discord Guru and want to be involved early? Reach out.</p>
            <p>We also take direction from the Founders Council which is made up by some of the biggest, most experienced NFT collectors on Fantom. Want to be on the Founders Council? You need to own a genesis token which are now officially sold out.</p>
        </>
    )
}]



export default FAQItems;