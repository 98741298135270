import { useContext, useState } from "react";
import { AccountContext, WalletType } from "../user/AccountContext";
import Button from "./Button";

export type ConnectWalletButtonProps = {
    className?: string;
    fontSize?: number;
}

const toShortWallet = (walletAddr: string): string => {
    return [
        walletAddr.substring(0, 4),
        walletAddr.substring(walletAddr.length - 4)
    ].join('...');
}

export const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
    className,
    fontSize
}) => {
    const accountContext = useContext(AccountContext);

    const handleButtonClick = () => {
        if (accountContext.account) {
            accountContext.disconnect();
        } else {
            accountContext.connect('MetaMask');
        }
    }


    let connectButtonText = 'Connect Wallet';
    if (accountContext.isConnecting) {
        connectButtonText = 'Connecting...';
    } else if (!!accountContext.account) {
        connectButtonText = toShortWallet(accountContext.account.walletAddress);
    }

    return (
        <>
            <Button className={className} onClick={handleButtonClick}>
                {connectButtonText}
            </Button>
        </>
    )
}