import classNames from "classnames";
import styled from "styled-components";
import { BiPlus, BiMinus } from 'react-icons/bi';

export type AmountSelectorProps = {
    min: number;
    max: number;
    onChange: (value: number) => void;
    value: number;
    className?: string;
}


const Container = styled.div`
    color: #000;
    height: 55px;
    width: 200px;
    border: 3px solid #392E2C;
    border-radius: .5rem;
`;

const Amount = styled.div`
    text-align: center;
    font-family: Gotham;
    font-size: 25px;
    font-weight: 700;
    margin-top: .4rem;
`;

const AmountButton = styled.button`
    display: block;
    padding: 0 1rem;

    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
`;

const AmountSelector: React.FC<AmountSelectorProps> = ({
    className,
    min,
    max,
    onChange,
    value
}) => {
    const containerClasses = classNames(className, 'flex', 'justify-between')

    const handleDecrease = () => {
        const newValue = Math.max(value - 1, min);
        onChange(newValue);
    }

    const handleIncrease = () => {
        const newValue = Math.min(value + 1, max);
        onChange(newValue);
    }

    return (
        <Container className={containerClasses}>
            <AmountButton onClick={handleDecrease} disabled={value <= min}>
                <BiMinus size={30} />
            </AmountButton>
            <Amount>{value}</Amount>
            <AmountButton onClick={handleIncrease} disabled={value >= max}>
                <BiPlus size={30} />
            </AmountButton>
        </Container>
    )
}

export default AmountSelector;