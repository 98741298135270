import { ComponentPropsWithRef } from "react";
import styled from "styled-components";

export type ProgressBarProps = {
    min: number;
    max: number;
    value: number;
    className?: string;
}

type BarProps = ComponentPropsWithRef<'div'> & {
    percentageWidth: string
}

const RootContainer = styled.div`

`;

const BarContainer = styled.div`
    height: 25px;
    background-color: #8cd28c;
    border: 3px solid #392E2C;
    border-radius: .5rem;
`;

const Bar = styled.div<BarProps>`
    background-color: #00a600;
    border-radius: .5rem;
    border: 3px solid #392E2C;
    margin-left: -3px;
    margin-top: -3px;
    height: 100%;
    width: ${props => props.percentageWidth};
    height: 25px;
    transition: width .75s ease-in-out;
`;

const Count = styled.div`
    font-family: Gotham;
    font-size: 2rem;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({
    min,
    max,
    value,
    className
}) => {
    const percentage = ((value === 0) ? value : (value / (max - min) * 100)).toFixed(2) + '%';
    return (
        <RootContainer className={className}>
            <BarContainer className="flex rounded-sm" role="progressbar">
                <Bar className="rounded-sm" percentageWidth={percentage} />
            </BarContainer>
            <div className="flex justify-center mt-1">
                <div />
                <Count>{value} / {max - min}</Count>
            </div>
        </RootContainer>
    )
}

export default ProgressBar