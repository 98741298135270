import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { useContext } from 'react';
import { AccountContext } from './user/AccountContext';
import Mint from './mint';
import Header from './Header';
import Footer from './Footer';
import FAQ from './faq/FAQ';

const AppContainer = styled.div``;

const ViewContent = styled.div`
    text-align: center;
`;

const SocialImage = styled.img`
    width: 3rem;
`;

const App = () => {
    // Wait for accountContext to be initialized before proceeding
    const accountContext = useContext(AccountContext);
    if (!accountContext) {
        return <div>Loading...</div>
    }

    return (
        <>
            <AppContainer>
                <ViewContent>
                    <Header />
                    <Mint />

                    <div className="flex justify-center mt-12 mb-8">
                        <div className="flex">
                            <a className='mx-4' href="https://twitter.com/GothamApes" target="_blank">
                                <SocialImage src="/images/twitter.png" />
                            </a>
                            <a className='mx-4' href="https://discord.gg/SZTm6dUNAr" target="_blank">
                                <SocialImage src="/images/discord.png" />
                            </a>
                        </div>
                    </div>

                    <FAQ />
                    <Footer />
                </ViewContent>
            </AppContainer>

            <ToastContainer />
        </>
    );
}

export default App;
