import classNames from "classnames";
import { useState } from "react";
import styled from "styled-components";
import useUser from "../user/useUser";
import useMintDetails from "./useMintDetails";
import useMintContract from "./useMintContract";
import { MintState } from "./useMintContract/MintContract";
import AmountSelector from '../components/AmountSelector';
import { toast } from "react-toastify";
import { ConnectWalletButton } from "../components/ConnectWalletButton";
import Button from "../components/Button";
import ProgressBar from "../components/ProgressBar";
import { CHAIN_ID } from "../user/Chain";

const CONTRACT_ADDRESS = '0xCEaf55e9E6aF1d0922135Bdc64b6c4C7f07E4636';

export type MintProps = {
    className?: string;
}

const Container = styled.div``;

const MintContainer = styled.div`
    max-width: 520px;
`;

const Description = styled.div`
    font-family: Gotham;
    font-size: 1.25rem;
    font-weight: 600;

    p {
        margin-bottom: .5rem;
    }
`;

const MarketplaceIcon = styled.img`
    width: 40px;
`;

const Big = styled.span`
    font-size: 2rem;
`;

const WrongNetwork = styled.div`
    font-size: 1.5rem;
`;

const toShortWallet = (walletAddr: string): string => {
    return [
        walletAddr.substring(0, 4),
        walletAddr.substring(walletAddr.length - 4)
    ].join('...');
}

const Mint: React.FC<MintProps> = ({
    className
}) => {
    const mintContract = useMintContract(CONTRACT_ADDRESS);
    const mintDetails = useMintDetails(mintContract);
    const [selectedAmount, setSelectedAmount] = useState(mintDetails?.whitelistSpots === 0 ? 0 : 1);
    const user = useUser();
    const [isMinting, setIsMinting] = useState(false);

    if (!mintContract || !mintDetails) {
        return <div>Loading...</div>
    }

    const isSoldOut = mintDetails.mintedSupply >= mintDetails.maxSupply;
    const mintButtonsDisabled = (
        isMinting ||
        isSoldOut ||
        mintDetails.mintState === MintState.NotStarted ||
        (mintDetails.mintState === MintState.Whitelist && mintDetails.whitelistSpots === 0)
    );

    const handleMintClick = async () => {
        setIsMinting(true);
        try {
            await mintContract.mint(selectedAmount);
            toast('Mint successful', { type: 'success', theme: 'colored' });

            if (mintDetails.mintState === MintState.Whitelist) {
                await mintContract.getWhitelistSpots();
            }
        } catch (e) {
            console.log(`Error on minting`, e);
            toast('Minting failed, please try again', { type: 'error', theme: 'colored' });
        } finally {
            setIsMinting(false);
        }
    }

    const maxSelectable = mintDetails.mintState === MintState.Whitelist
        ? Math.min(mintDetails.maxPerTx, mintDetails.whitelistSpots || 1)
        : mintDetails.maxPerTx;

    const containerClasses = classNames('mt-12', 'px-4', 'mx-auto', className);
    return (
        <Container className={containerClasses}>

            <Description className="mb-8">
                <p>333 TOTAL TOKEN SUPPLY</p>
                <p>INDIVIDUALLY HAND DESIGNED</p>
                <p>DISCORD CHANNEL ACCESS + ROLE</p>
                <p>GENERATIVE MINT WHITELIST (Q3)</p>
                <p>FUTURE IRL EVENT ACCESS (Q4)</p>
                <p>MOAR TO BE REVEALED (SOON)</p>
            </Description>

            <div className="flex justify-center mt-10 mb-8">
                <div className="flex">
                    <a className='mx-4' href="https://campfire.exchange/collections/0xceaf55e9e6af1d0922135bdc64b6c4c7f07e4636" target="_blank">
                        <MarketplaceIcon src="/images/campfire.png" />
                    </a>
                    <a className='mx-4' href="https://nftkey.app/collections/somethingdifferent/" target="_blank">
                        <MarketplaceIcon src="/images/nftkey.png" />
                    </a>
                    <a className='mx-4' href="https://paintswap.finance/marketplace/collections/0xceaf55e9e6af1d0922135bdc64b6c4c7f07e4636" target="_blank">
                        <MarketplaceIcon src="/images/paintswap.png" />
                    </a>
                </div>
            </div>
            <Description className="mb-4">
                <Big>333 FTM</Big><br />
                (2 MAX PER WALLET)
            </Description>

            <MintContainer className='mx-auto'>
                <ProgressBar
                    max={mintDetails.maxSupply}
                    min={0}
                    value={mintDetails.mintedSupply}
                    className='mb-4'
                />

                <div className="text-center mb-4">
                    {!!user.account ? (
                        <div>
                            <p className="mb-2">
                                Connected with wallet <b>{toShortWallet(user.account.walletAddress)}</b>
                            </p>

                            {(user.account.networkId !== CHAIN_ID) ? (
                                <WrongNetwork className="mt-6">
                                    You are connected into wrong chain,<br />
                                    please connect to <b>Fantom Opera</b>.
                                </WrongNetwork>
                            ) : (
                                <>
                                    {
                                        mintDetails.mintState === MintState.Whitelist && (
                                            <p className="mb-2">
                                                Whitelist sale is currently active and your wallet is eligible for <br />
                                                <b>{mintDetails.whitelistSpots} whitelist spots.</b>
                                            </p>
                                        )
                                    }

                                    <div className="flex items-center justify-center">
                                        <AmountSelector
                                            min={1}
                                            max={maxSelectable}
                                            onChange={(value) => setSelectedAmount(value)}
                                            value={selectedAmount}
                                        />

                                        <Button onClick={handleMintClick} disabled={mintButtonsDisabled} className="mx-2">
                                            {isMinting ? 'MINTING...' : 'MINT'}
                                        </Button>
                                    </div>
                                </>
                            )}

                        </div>
                    ) : (
                        <ConnectWalletButton />
                    )}
                </div>
            </MintContainer>
        </Container >
    )
}

export default Mint;