import MintContract from "./useMintContract/MintContract";
import { useEffect, useRef, useState } from "react";
import { MintContractEvents, MintState } from "./useMintContract/MintContract";
import { BigNumber } from "ethers";

export type MintDetails = {
    maxSupply: number;
    mintedSupply: number;
    mintState: number;
    mintPrice: BigNumber;
    maxPerTx: number;
    whitelistSpots: number;
}

const useMintDetails = (mintContract: MintContract | null): MintDetails | null => {
    const [mintDetails, setMintDetails] = useState<MintDetails | null>(null);

    const mintedSupplyCallback = (mintedSupply: number) => {
        setMintDetails(
            (state) => (state) ? { ...state, mintedSupply } : state
        );
    }

    const mintStateCallback = (mintState: MintState) => {
        setMintDetails(
            (state) => (state) ? { ...state, mintState } : state
        );
    }

    const savedMintedSupplyCallback = useRef(mintedSupplyCallback)
    const savedMintStateCallback = useRef(mintStateCallback);

    useEffect(() => {
        savedMintedSupplyCallback.current = mintedSupplyCallback;
    }, [mintedSupplyCallback]);

    useEffect(() => {
        savedMintStateCallback.current = mintStateCallback;
    }, [mintStateCallback]);

    useEffect(() => {
        const init = async () => {
            if (mintContract) {
                try {
                    console.log('Initializing mint details', mintContract);

                    const [maxSupply, mintState, mintedSupply, maxPerTx, mintPrice, whitelistSpots] = await Promise.all([
                        mintContract.getMaxSupply(),
                        mintContract.getMintState(),
                        mintContract.getMintedSupply(),
                        mintContract.getMaxPerTx(),
                        mintContract.getMintPrice(),
                        mintContract.getWhitelistSpots()
                    ]);

                    setMintDetails({
                        maxSupply,
                        mintedSupply,
                        mintState,
                        maxPerTx,
                        whitelistSpots,
                        mintPrice,
                    });

                    mintContract.on(MintContractEvents.MintSupplyUpdated, mintedSupplyCallback);
                    mintContract.on(MintContractEvents.MintStateUpdated, mintStateCallback);
                } catch (e) {
                    console.error('Initializing minting failed', e);
                    throw e;
                }
            }
        }

        init();

        return () => mintContract?.clear();
    }, [mintContract]);

    return mintDetails;
}

export default useMintDetails;